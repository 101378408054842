@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  // GuideLink
  & + .guideLink {
    margin-top: 0;
    position: relative;
  }

  .mainvisual {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1/0.588;
    border-radius: 32px 0 0 0;
    position: relative;
    overflow: hidden;

    @include mixin.sp {
      height: calc(493px + 60px);
      border-radius: 26px 0 0 0;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.213;
      background-image: url(../img/top/wave-top.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
    }

    &_inner {
      display: block;
      width: calc(100% - 40px);
      min-width: 1220px;
      height: auto;
      aspect-ratio: 1/0.588;
      position: fixed;
      top: 22px;
      left: 20px;
      border-radius: 32px 0 0 0;
      overflow: hidden;

      @include mixin.sp {
        width: calc(100% - 28px);
        min-width: 347px;
        height: calc(493px + 60px);
        background-color: #ccc;
        top: 16px;
        left: 14px;
      }
    }

    &_title {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: flex-start;
      width: calc(100% - 120px);
      max-width: 1008px;
      position: absolute;
      z-index: 41;
      left: 54px;
      bottom: 18%;
      margin: 0;
      font-size: 5.1rem;
      line-height: calc(68 / 51);
      color: color.$site-main-blue;

      @include mixin.sp {
        row-gap: 10px;
        width: 100%;
        top: 430px;
        bottom: auto;
        left: 15px;
        font-size: 2.5rem;
      }

      &_row {
        display: block;
        padding: 0 0.15em 0 0.2em;
        background: linear-gradient(transparent 83%, color.$site-main-yellow 83%);
      }
    }

    &_content {
      display: block;
      width: 100%;
      height: auto;
      position: relative;

      @include mixin.sp {
        width: auto;
        height: 100%;
        aspect-ratio: 1.7/1;
        left: 50%;
        transform: translateX(-50%);
      }

      &_bg {
        display: block;
        width: 100%;
        height: auto;

        // Modifier
        &-z1 {
          position: relative;
          top: 0;
          left: 0;
          z-index: 1;
        }
        &-z2 {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 31;
        }
        &-z3 {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 21;
        }
      }

      &_apng {
        &_item {
          display: block;
          position: absolute;
          z-index: 10;

          // Modifier
          &-coco {
            width: 30%;
            top: -1.2%;
            left: 32.1%;
          }
          &-dog {
            width: 5.9%;
            top: 55.5%;
            left: 67.6%;
          }
          &-kouji {
            width: 5.9%;
            top: 61%;
            left: 49.1%;
          }
          &-mother {
            width: 4.4%;
            top: 43.1%;
            left: 32.8%;
          }
          &-trash {
            width: 4.4%;
            top: 66.9%;
            left: 65%;
          }
          &-yunbo {
            width: 6.2%;
            top: 55.6%;
            left: 49.2%;
          }
        }
      }

      &_animation {
        &_item {
          display: block;
          position: absolute;
          z-index: 5;

          // Modifier
          &-paraglider {
            width: 12.8%;
            top: -14.6%;
            left: 10.8%;
            // top: -0.6%;
            // left: 13.8%;
          }
          &-train {
            width: 21.5%;
            top: 18.8%;
            left: -22.3%;
            // top: 53.8%;
            // left: 12.7%;
          }
          &-car1 {
            width: 4.2%;
            top: 6%;
            left: 46.2%;
          }
          &-car2 {
            top: 26.5%;
            left: 66.1%;
            width: 4.2%;
          }
          &-car3 {
            width: 4.2%;
            top: 63.3%;
            left: 30.5%;
          }
        }
      }
    }
  }

  .strong {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -1px;

    @include mixin.sp {
      // margin-top: -60px;
    }

    &_inner {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 111px;
      width: 100%;
      color: color.$write-white;
      background-color: #003b99;
      padding: 146px 0 169px;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 40px;
        padding: 60px 0 80px;
      }
    }

    &_ico {
      display: block;
      width: 100%;
      max-width: 197px;

      @include mixin.sp {
        max-width: 120px;
      }
    }

    &_text {
      display: block;
      width: 100%;
      max-width: 573px;

      @include mixin.sp {
        width: calc(100% - 40px);
      }

      &_title {
        font-size: 5.1rem;
        line-height: calc(51 / 51);
        margin: 0 0 33px;

        @include mixin.sp {
          font-size: 3rem;
          margin-bottom: 25px;
        }

        &_point {
          position: relative;

          &::before {
            content: "";
            width: 10px;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 100%;
            background-color: color.$bg-white;
            position: absolute;
            top: 4px;
            left: calc(50% - 1px);
            transform: translateX(-50%);

            @include mixin.sp {
              width: 8px;
              top: -2px;
            }
          }
        }
      }

      &_description {
        font-size: 2.1rem;
        line-height: calc(28 / 21);
        margin: 21px 0 0;

        @include mixin.sp {
          font-size: 1.5rem;
          margin-top: 15px;
        }
      }

      &_link {
        display: flex;
        flex-wrap: wrap;
        column-gap: 18px;
        row-gap: 18px;
        width: 100%;
        margin-top: 33px;

        @include mixin.sp {
          justify-content: center;
          column-gap: 0;
          row-gap: 15px;
          margin-top: 25px;
        }

        &_item {
          display: block;
          min-width: 200px;
          border-radius: 25px;
          border: 2px solid color.$site-main-blue;
          font-size: 2.1rem;
          line-height: calc(30 / 21);
          color: color.$site-main-blue;
          background-color: color.$bg-white;
          text-align: center;
          text-decoration: none;
          margin: 0;
          padding: 2px 35px 4px 15px;
          position: relative;
          transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;

          @include mixin.sp {
            width: 100%;
            max-width: 300px;
            border-radius: 19px;
            font-size: 1.8rem;
            padding: 10px 38px 11px 10px;
          }

          &::after {
            content: "";
            display: block;
            width: 27px;
            height: 3px;
            background-color: color.$site-main-blue;
            position: absolute;
            top: 50%;
            right: -2px;
            transform: translateY(-50%);
            transition: background-color 0.3s ease;

            @include mixin.sp {
              width: 35px;
            }
          }

          @include mixin.hover {
            color: color.$write-white;
            background-color: color.$site-main-blue;
            border-color: color.$write-white;

            &::after {
              background-color: color.$bg-white;
            }
          }
        }
      }
    }
  }

  .topCococheck {
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: color.$bg-white;
    margin-top: -1px;
    padding: 0 0 114px;

    @include mixin.sp {
      padding: 0 0 0;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.21;
      background-image: url(../img/top/slash-bottom.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: -1px;
      left: 0;
    }

    // Cococheck
    .cococheck {
      margin-top: 0;

      &_title {
        color: color.$write-white;

        @include mixin.sp {
          transform: translateY(-20px);
          margin-bottom: -5px;
        }
      }

      // JS Splide
      &.splide {
        .splide__arrows {
          .splide__arrow {
            background-image: url(../img/common/ico_arrow_white.svg);

            @include mixin.sp {
              background-image: url(../img/common/icon_arrow_blue.svg);
            }

            &--next {
              background-image: url(../img/common/icon_arrow_blue.svg);

              @media screen and (min-width: 2385px) {
                background-image: url(../img/common/ico_arrow_white.svg);
              }
            }
          }
        }
      }
    }

    .banner {
      display: flex;
      flex-wrap: wrap;
      column-gap: 14px;
      row-gap: 14px;
      width: 100%;
      max-width: 1080px;
      margin: 28px auto 0;
      position: relative;

      @include mixin.sp {
        flex-direction: column;
        column-gap: 0;
        row-gap: 12px;
        width: calc(100% - 40px);
        margin-top: 20px;
      }

      &_item {
        display: flex;
        align-items: flex-start;
        column-gap: 49px;
        width: calc((100% / 2) - (14px / 2));
        border: 1px solid #707070;
        background-color: #fffef0;
        text-decoration: none;
        padding: 21px 18px 20px;
        @include mixin.hoverOpacity60;

        @include mixin.sp {
          flex-direction: column;
          row-gap: 10px;
          width: 100%;
        }

        &_image {
          display: block;
          width: 238px;
          height: auto;
          aspect-ratio: 1/0.66;

          @include mixin.sp {
            width: 100%;
          }

          & > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &_text {
          display: block;
          width: calc(100% - 238px - 49px);
          padding-top: 20px;

          @include mixin.sp {
            width: 100%;
            padding-top: 15px;
          }

          &_title {
            font-size: 2rem;
            line-height: calc(29 / 20);
            position: relative;
            z-index: 1;
            margin: 0 0 12px;

            @include mixin.sp {
              font-size: 1.8rem;
              padding-left: 1.2em;
            }

            &::before {
              content: "";
              display: block;
              vertical-align: middle;
              width: 49px;
              height: auto;
              aspect-ratio: 1/0.98;
              background-image: url(../img/common/icon_title.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100% auto;
              position: absolute;
              z-index: -1;
              top: -18px;
              left: -29px;

              @include mixin.sp {
                width: 39px;
                top: -16px;
                left: -6px;
              }
            }
          }

          &_description {
            font-size: 1.6rem;
            line-height: calc(21 / 16);
            margin: 0;

            @include mixin.sp {
              font-size: 1.4rem;
              padding: 0 1em;
            }
          }

          &_more {
            display: block;
            width: 100%;
            max-width: 138px;
            font-size: 1.5rem;
            line-height: calc(21 / 15);
            background-color: color.$bg-white;
            border: 1px solid #707070;
            padding: 6px 12px 5px;
            margin: 19px 0 0 auto;
            transform: translateX(19px);

            @include mixin.sp {
              max-width: 120px;
              font-size: 1.3rem;
              padding: 4px 8px 4px;
              margin-top: 25px;
            }
          }
        }
      }
    }
  }

  .topics {
    display: block;
    width: 100%;
    background-color: color.$bg-white;
    position: relative;
    padding-top: 114px;

    @include mixin.sp {
      padding-top: 50px;
    }

    &_inner {
      display: block;
      width: 100%;
      max-width: 796px;
      margin: 0 auto;

      @include mixin.sp {
        width: calc(100% - 40px);
      }
    }

    &_head {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 2px solid color.$write-blue;
      padding-bottom: 12px;

      &_title {
        font-size: 2.1rem;
        line-height: calc(30 / 21);
        margin: 0;
        color: color.$write-blue;

        @include mixin.sp {
          font-size: 1.9rem;
        }

        &::before {
          content: "";
          display: inline-block;
          width: 35px;
          height: auto;
          aspect-ratio: 1/1.714;
          background-image: url(../img/top/ico_balloon.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          transform: translateY(16px);

          @include mixin.sp {
            width: 25px;
            transform: translateY(12px);
            margin-right: 4px;
          }
        }
      }

      &_link {
        display: block;
        width: 66px;
        font-size: 1.7rem;
        line-height: calc(25 / 17);
        color: color.$write-blue;
        background-color: #e8edf6;
        padding: 6px 6px 7px;
        text-align: center;
        text-decoration: none;
        border-radius: 13px;
        transform: translateY(3px);
        transition: color 0.3s ease, background-color 0.3s ease;

        @include mixin.hover {
          color: color.$write-white;
          background-color: color.$write-blue;
        }

        @include mixin.sp {
          font-size: 1.5rem;
          padding: 2px 4px 4px;
          transform: translateY(2px);
        }
      }
    }

    &_list {
      display: block;
      width: 100%;
      margin-top: 33px;

      @include mixin.sp {
        margin-top: 25px;
      }

      &_item {
        display: block;
        width: 100%;
        margin-top: 20px;

        @include mixin.sp {
          margin-top: 25px;
        }

        &_inner {
          display: flex;
          align-items: center;
          text-decoration: none;
          column-gap: 10px;

          @include mixin.sp {
            column-gap: 10px;
            row-gap: 6px;
            flex-wrap: wrap;
          }
        }

        &_category {
          display: inline-block;
          min-width: 83px;
          text-align: center;
          font-size: 1.6rem;
          color: color.$site-main-blue;
          border-radius: 14px;
          padding: 3px 9px;
          background-color: color.$site-main-yellow;
          white-space: nowrap;

          @include mixin.sp {
            min-width: 71px;
            font-size: 1.3rem;
            border-radius: 12px;
          }
        }

        &_date {
          display: inline-block;
          font-size: 1.6rem;
          color: color.$site-main-blue;
          white-space: nowrap;
          margin: 0 12px 0 9px;

          @include mixin.sp {
            margin: 0;
            font-size: 1.5rem;
          }
        }

        &_title {
          font-size: 1.6rem;
          line-height: calc(24 / 16);
          margin: 0;

          @include mixin.sp {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .instagram {
    display: block;
    width: 100%;
    background-color: color.$bg-white;
    position: relative;
    padding: 105px 0 113px;

    @include mixin.sp {
      padding: 80px 0 0;
    }

    // Main Title
    .main_title {
      margin-top: 0;

      @include mixin.pc {
        margin-bottom: 76px;
      }
    }

    &_feed {
      display: block;
      width: 100%;
      max-width: 989px;
      margin: 24px auto 0;

      @include mixin.sp {
        width: calc(100% - 40px);
        margin-top: 25px;
      }

      // WordPress Smash Balloon
      #sb_instagram .sbi_photo {
        width: 100%;
        height: auto !important;
        aspect-ratio: 1/1;
      }
    }

    &_linkButton {
      display: block;
      width: 100%;
      max-width: 413px;
      border-radius: 25px;
      border: 2px solid #e8edf6;
      font-size: 2.8rem;
      line-height: calc(40 / 28);
      color: color.$site-main-blue;
      background-color: #e8edf6;
      text-align: center;
      text-decoration: none;
      margin: 57px auto 0;
      padding: 7.5px 30.5px 7.5px 7.5px;
      position: relative;
      transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;

      @include mixin.sp {
        max-width: 300px;
        border-radius: 19px;
        font-size: 1.8rem;
        margin-top: 30px;
        padding: 10px 38px 11px 10px;
      }

      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 3px;
        background-color: color.$site-main-blue;
        position: absolute;
        top: 50%;
        right: -2px;
        transform: translateY(-50%);
        transition: background-color 0.3s ease;

        @include mixin.sp {
          width: 35px;
        }
      }

      @include mixin.hover {
        color: color.$write-white;
        border-color: color.$site-main-blue;
        background-color: color.$site-main-blue;

        &::after {
          background-color: color.$bg-white;
        }
      }
    }
  }

  .topWorksSlider {
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: color.$bg-white;
    padding: 113px 0 41px;

    @include mixin.sp {
      padding: 80px 0 0;
    }

    // Works Slider
    .worksSlider {
      margin-top: 0;

      &_linkButton {
        border-color: #e8edf6;
        background-color: #e8edf6;
        transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;

        @include mixin.hover {
          border-color: color.$site-main-blue;
          background-color: color.$site-main-blue;
        }
      }
    }
  }

  .movie {
    display: block;
    width: 100%;
    background-color: color.$bg-white;
    padding: 113px 0 41px;
    position: relative;
    z-index: 2;

    @include mixin.sp {
      padding: 80px 0 0;
    }

    &_inner {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/0.563;
      overflow: hidden;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
      }
    }

    &_youtube {
      width: calc(100% + 16px);
      height: calc(100% + 9px);
      cursor: none;
      position: relative;
      z-index: 1;
      transform: translate(-8px, -4.5px);
    }
  }

  .topWhats {
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: color.$bg-white;
    padding: 113px 0 239px;

    @include mixin.sp {
      padding: 80px 0;
    }

    // whats
    .whats {
      margin-top: 0;
    }
  }
}
